import dayjs from 'dayjs';

import { ITaskData } from '../types/ITaskData';

const defaultProperty: ITaskData = {
    deadline: dayjs(dayjs().format('YYYY/MM/DD')),
    taskName: '',
    contact: null,
    phone: '',
    serviceClasse: null,
    project: null,
    taskTypes: null,
    criteria: '',
    desc: '',
    filesUploader: [],
    observers: [],
    assignee: null,
    role: null,
    comment: '',
    services: null,
    functions: [],
    orgstructure: null,
    doctype: null,
    indate: null,
    innumber: null,
    intotal: null,
    firm: null,
    contragent: null,
    contract: null,
};

export default defaultProperty;
