/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useReducer, useEffect, BaseSyntheticEvent, useRef, useState,
    useMemo,
} from 'react';
import {
    FormProvider, UseFormProps, UseFormReturn, useForm,
} from 'react-hook-form';

import dayjs from 'dayjs';

import { saveOptionsService } from 'Domain/Options/optionsService.js';

import { useGetContragentsSimpleSetQuery, useGetFirmsSimpleSetQuery } from 'Endpoints/Contragents/contragents.api';

import { Modal } from 'Ui/Modal';

import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';
import container from 'Services/container';
import di from 'Services/di.js';

import defaultFormProperty from '../../constants/defaultProperty';
import defaultDataController from '../../events/models/defaultDataController';
import backdropModalController from '../../models/backdropModalController';
import createDataTaskModel from '../../models/createDataTaskModel';
import createTaskByEvent from '../../models/createTaskByEvent';
import handleOnClose from '../../models/handleOnClose';
import reducer from '../../models/reducer';
import titleController from '../../models/titleConroller';
import checkTaskIdByMessageUid from '../../services/checkTaskIdByMessageUid';
import getOptionsForForm from '../../services/getOptionsForForm';
import postComment from '../../services/postComment';
import { IDataForm } from '../../types/IDataForm';
import IDataFromEvent from '../../types/IDataFromEvent';
import { ITaskData } from '../../types/ITaskData';
import AccordionView from '../AccordionView/AccordionView';
import Footer from '../Footer/Footer';
import GridView from '../GridView/GridView';
import ModalConfirm from '../ModalConfirm/ModalConfirm';

function ModalWrapper(
    {
        type,
        params,
        messageId,
        callback,
        dataFiles,
    } : IDataFromEvent,
) {
    const { events } = container;
    const [state, dispatch] = useReducer(
        reducer,
        {
            open: true,
            dataForm: {
                projects: [],
                contacts: [],
                serviceClasses: [],
                taskTypes: [],
                users: [],
                roles: [],
                services: [],
                functions: [],
                orgstructure: [],
                docTypes: [],
                subdivisionIsRequired: 0,
                itserviceIsRequired: 0,
                functionsIsRequired: 0,
                tasks: [],
            },
            suspend: true,
            openConfirm: false,
            newViewMiniForm: false,
            widthLeftSide: undefined,
        },
    );

    const currentTimeRef = useRef(dayjs().unix());

    const { error, success, warning } = useEnqueueSnackbar();

    const form: UseFormReturn<ITaskData, UseFormProps> = useForm<ITaskData>({
        defaultValues: defaultFormProperty,
    });

    const { data: firms = [] } = useGetFirmsSimpleSetQuery();
    const { data: contragents = [] } = useGetContragentsSimpleSetQuery();

    const [boxWidth, setBoxWidth] = useState<number | undefined>(undefined);
    const [boxHeight, setBoxHeight] = useState<number | undefined>(undefined);

    const onSubmit = async (
        data: ITaskData,
        event: BaseSyntheticEvent<object, any, any> | undefined,
    ) => {
        dispatch({
            type: 'suspend',
            payload: true,
        });

        if (type === 'taskByInputMail' && messageId) {
            const response = await checkTaskIdByMessageUid(messageId);
            if (response?.id && response?.taskId) {
                dispatch({
                    type: 'suspend',
                    payload: false,
                });
                warning(
                    'Задача по этому письму уже существует',
                    {
                        actionText: `${response.taskId}`,
                        callback: () => events.emit('oldAppInbox:openTask', response.taskId),
                    },
                );

                return null;
            }
        }

        const eventId = event?.target.id || 'create';
        const baseDataTask = await createDataTaskModel(type, data, eventId);
        const result = await createTaskByEvent(baseDataTask!, eventId, currentTimeRef);
        dispatch({
            type: 'suspend',
            payload: false,
        });
        if (result.success) {
            dispatch({
                type: 'open',
                payload: false,
            });
            if (result?.type === 'template') {
                success('Шаблон успешно создан');
            } else {
                if (data.comment) await postComment(result.id, data.comment);
                success(
                    'Создана задача №',
                    {
                        actionText: `${result.id}`,
                        callback: () => di.events.emit('oldAppInbox:openTask', result.id),
                        onExited: handleOnClose,
                    },
                );
            }

            if (callback) callback(result, baseDataTask);
        }
        if (!result.success) error('Не удалось создать новую задачу');

        return null;
    };

    const disabledFields = useMemo(() => {
        if (params?.data?.taskTypes) {
            return ['taskTypes'] as (keyof ITaskData)[];
        }
        return [] as (keyof ITaskData)[];
    }, [params?.data?.taskTypes]);

    useEffect(() => {
        defaultDataController(type, params, messageId, dataFiles)
            .then((data) => {
                form.reset(data.defaultProperty);
                const currentProject = data.defaultProperty.project;
                const formData: IDataForm = {
                    ...data.data,
                    subdivisionIsRequired: currentProject?.subdivisionIsRequired || 0,
                    itserviceIsRequired: currentProject?.itserviceIsRequired || 0,
                    functionsIsRequired: currentProject?.functionsIsRequired || 0,
                };
                dispatch({
                    type: 'updateDataForm',
                    payload: formData,
                });
                dispatch({
                    type: 'suspend',
                    payload: false,
                });
            });
        getOptionsForForm()
            .then((res) => {
                if (!res.success) {
                    setBoxWidth(1200);
                    setBoxHeight(777);
                    return;
                }
                const { options } = res;
                const { optionValue } = options[0];
                const optionsColumns = JSON.parse(optionValue)[0];
                setBoxWidth(optionsColumns.miniForm.boxWidth);
                setBoxHeight(optionsColumns.miniForm.boxHeight);
                dispatch({
                    type: 'changeViewMiniForm',
                    payload: optionsColumns.newViewMiniForm,
                });
                dispatch({
                    type: 'changeWidthLeftSide',
                    payload: optionsColumns.widthLeftSide,
                });
            });
    }, []);

    const handlerChangeModalSize = (obj: {height: string; width: string}) => {
        setBoxWidth(parseFloat(obj.width));
        setBoxHeight(parseFloat(obj.height));
    };

    const saveOptions = async () => {
        const result = await saveOptionsService('miniForm', [
            {
                miniForm: { boxWidth, boxHeight },
                newViewMiniForm: state.newViewMiniForm,
                widthLeftSide: state.widthLeftSide,
            },
        ]);

        if (result.success) {
            return success('Настройки формы успешно сохранены!');
        }

        return error('Во время сохранения произошла ошибка, либо вы не изменили настройки формы!');
    };

    return (
        <>
            {state.open && boxWidth && (
                <Modal
                    title={titleController(type)}
                    open={state.open}
                    width={boxWidth}
                    height={boxHeight}
                    minWidth={625}
                    onClose={
                        form.formState.isDirty
                            ? () => dispatch({ type: 'openConfirm', payload: true })
                            : handleOnClose
                    }
                    footer={(
                        <Footer
                            handleSubmit={form.handleSubmit(onSubmit)}
                            saveOptions={saveOptions}
                            type={type}
                            checked={state.newViewMiniForm}
                            dispatch={dispatch}
                        />
                    )}
                    suspend={state.suspend}
                    backdropType={backdropModalController(type)}
                    callback={handlerChangeModalSize}
                >
                    {!state.suspend && (
                        <FormProvider {...form}>
                            {
                                state.newViewMiniForm
                                    ? (
                                        <AccordionView
                                            type={type}
                                            data={state.dataForm}
                                        />
                                    )
                                    : (
                                        <GridView
                                            type={type}
                                            state={state}
                                            dispatch={dispatch}
                                            disabledFields={disabledFields}
                                            firms={firms}
                                            contragets={contragents}
                                        />
                                    )
                            }
                        </FormProvider>
                    )}
                </Modal>
            )}
            {state.openConfirm && <ModalConfirm dispatch={dispatch} />}
        </>
    );
}

export default ModalWrapper;
